import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-40cc524c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-grid" }
const _hoisted_2 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_3 = { for: "testType" }
const _hoisted_4 = {
  key: 0,
  class: "p-invalid"
}
const _hoisted_5 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_6 = { for: "specialist" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { class: "dropdown-item" }
const _hoisted_10 = {
  key: 0,
  class: "p-invalid"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    visible: _ctx.showDialog,
    "onUpdate:visible": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.showDialog) = $event)),
    header: _ctx.modalLabel,
    modal: true,
    class: "p-fluid"
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_Button, {
        label: _ctx.$t('common.cancel'),
        class: "p-button p-component p-button-secondary p-button-medium",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showDialog = false))
      }, null, 8, ["label"]),
      _createVNode(_component_Button, {
        label: _ctx.$t('common.save'),
        class: "p-button p-component p-button-info p-button-medium",
        loading: _ctx.submitting,
        onClick: _ctx.onSubmit
      }, null, 8, ["label", "loading", "onClick"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("p", null, _toDisplayString(!_ctx.editMode ? _ctx.$t('plan.modalDescription') : _ctx.$t('plan.modalDescriptionEdit')), 1),
      _createElementVNode("form", null, [
        _createElementVNode("div", _hoisted_1, [
          _withDirectives(_createElementVNode("div", _hoisted_2, [
            _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.$t('plan.followUp')) + "*", 1),
            _createVNode(_component_Dropdown, {
              id: "testType",
              modelValue: _ctx.planFormDto.type,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.planFormDto.type) = $event)),
              required: "true",
              placeholder: _ctx.$t('plan.select-type'),
              options: _ctx.planTypeOptions,
              "option-label": "label",
              "option-value": "value",
              disabled: _ctx.editMode,
              class: _normalizeClass({ 'p-invalid': _ctx.showValidation && !_ctx.validatedCreatePlanForm.type.valid })
            }, null, 8, ["modelValue", "placeholder", "options", "disabled", "class"]),
            (_ctx.showValidation && !_ctx.validatedCreatePlanForm.type.valid)
              ? (_openBlock(), _createElementBlock("small", _hoisted_4, _toDisplayString(_ctx.$t(`${_ctx.validatedCreatePlanForm.type.message}`)), 1))
              : _createCommentVNode("", true)
          ], 512), [
            [_vShow, !_ctx.editMode]
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.$t('plan.main-specialist')) + "*", 1),
            _createVNode(_component_Dropdown, {
              id: "specialist",
              modelValue: _ctx.planFormDto.professionalId,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.planFormDto.professionalId) = $event)),
              required: "true",
              options: _ctx.specialistOptions,
              optionValue: "professionalId",
              placeholder: _ctx.$t('plan.select-specialist'),
              class: _normalizeClass({
              'p-invalid': _ctx.showValidation && !_ctx.validatedCreatePlanForm.professionalId.valid,
            })
            }, {
              value: _withCtx((slotProps) => [
                (slotProps.value)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.specialistOptions?.find((specialist) => specialist.professionalId == slotProps.value)?.name) + " " + _toDisplayString(_ctx.specialistOptions?.find((specialist) => specialist.professionalId == slotProps.value)?.surname), 1))
                  : (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(slotProps.placeholder), 1))
              ]),
              option: _withCtx((slotProps) => [
                _createVNode(_component_Avatar, {
                  image: slotProps.option.picture,
                  class: "p-mr-2",
                  shape: "circle"
                }, null, 8, ["image"]),
                _createElementVNode("span", _hoisted_9, _toDisplayString(slotProps.option.name) + " " + _toDisplayString(slotProps.option.surname), 1)
              ]),
              _: 1
            }, 8, ["modelValue", "options", "placeholder", "class"]),
            (_ctx.showValidation && !_ctx.validatedCreatePlanForm.professionalId.valid)
              ? (_openBlock(), _createElementBlock("small", _hoisted_10, _toDisplayString(_ctx.$t(`${_ctx.validatedCreatePlanForm.professionalId.message}`)), 1))
              : _createCommentVNode("", true)
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["visible", "header"]))
}